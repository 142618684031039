import { getAuth0Instance } from "./authWrapper";

export const authGuard = (to, from, next) => {
  const authService = getAuth0Instance();

  const fn = async() => {
    if (authService.isAuthenticated) {
      return next();
    }

    if(authService.error && authService.error.error !== undefined) {
      //ToDo: Improve error handling
      console.error(JSON.stringify(authService.error))
      alert("Authentication failed:\n" + authService.error.error_description + '\nYou will be redirected to the login screen.')
      await authService.logout();
    }

    next('/session/login');
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};
