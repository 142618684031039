import Vue from 'vue'
import Vuex from 'vuex'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(Vuex)

//Parse URL Parameter
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

import api from './modules/api'
import center from './modules/center'
import locale from './modules/locale'
import background from './background'
import mirror from './mirror'
import tours from './tours'


function buildTestflightTourDE() {
  const tour = vue.$shepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
      classes: 'shadow-md bg-purple-dark',
      scrollTo: false,
    }
  });

  const standardButtons = {
    buttons: [
      {
        text: 'Zurück',
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: 'Weiter',
        action: tour.next
      }]
  }

  tour.addStep({
    title: "Home Screen",
    text: '<p>Der Home Screen ist die zentrale Oberfläche für Ihre Nutzer.</p><p>Von hier aus können alle Funktionalitäten aufgerufen werden und alle wichtigen Informationen sind verfügbar.</p>',
    buttons: [
      {
        text: 'Weiter',
        action: tour.next
      }
    ]
  });

  tour.addStep({
    title: "Home Screen",
    text: 'Aufgeteilt ist der Home Screen in drei Sektionen:<b><br/>- App Screen<br/>- Info Section<br/>- Status Bar</b>',
    ...standardButtons
  });

  tour.addStep({
    title: "App Screen",
    text: '<p>Der App Screen zeigt alle Funktionen und Apps, die Sie Ihren Nutzern freigegeben haben.</p><p>Dies können zum Beispiel RDP-Sitzungen, Webanwendungen oder eine der konfigurierbaren <b>ThinHoc Apps</b> sein.</p>',
    attachTo: {
      element: '#AppSelector',
      on: 'top'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Info Section",
    todo: "TODO: Update",
    text: '<p>Die Info Section ermöglicht es Ihnen, Nutzern in Echtzeit Informationen anzuzeigen.</p><p>Dies können je nach Ihrem Belieben und Bedarf beispielsweise Ankündigungen zu geplanten Wartungsarbeiten oder eventuell vorliegenden Serverproblemen sein, oder auch allgemeine Hinweise wie etwa die Durchwahl des IT-Helpdesks oder Linksammlungen zu Ihrem Intranet.</p><p>Ihre Nutzer sind so jederzeit bestens informiert und Sie können sich auf die wichtigen Aufgaben konzentrieren.</p>',
    attachTo: {
      element: '#InfoSection',
      on: 'top'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar",
    text: '<p>Die Status Bar zeigt Ihren Nutzern auf einen Blick Informationen zum System und dem Zustand Ihrer Infrastruktur an. Die angezeigten Indikatoren sind frei einstellbar.</p><p>Mit diesen Informationen wird die Abfrage von grundlegenden Informationen durch Ihren Helpdesk zum Kinderspiel.</p>',
    attachTo: {
      element: '#StatusBar',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Profil",
    text: '<p>Das aktuell aktivierte Profil ist hier ersichtlich.</p><p>Mit verschiedenen Profilen kann ein Arbeitsplatz für mehrere Funktionen genutzt werden, beziehungsweise im Funktionsumfang für die jeweiligen verschiedenen Nutzergruppen angepasst werden.</p>',
    attachTo: {
      element: '#StatusBarProfile',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Machine ID",
    text: '<p>Mit der Machine ID können Sie bei Supportanfragen sofort den Nutzer zum Gerät zuordnen.</p>',
    attachTo: {
      element: '#StatusBarMachineID',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Netzwerkübersicht",
    text: '<p>In der konfigurierbaren Netzwerkübersicht ist sofort zu sehen, ob die Internetverbindung aktiv ist und welche Dienste erreichbar sind.</p>',
    attachTo: {
      element: '#StatusBarNetworkStatus',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Vielen Dank!",
    text: '<p>Wir hoffen, Ihnen einen aussagekräftigen ersten Eindruck vermittelt zu haben.</p><p>Alle weiteren Informationen zu Soft- und Hardware finden Sie unter <a href="https://www.thinhoc.com" target="_blank">www.thinhoc.com</a>. Zögern Sie nicht, uns bei konkreten Rückfragen oder Wünschen zum Produkt zu kontaktieren - wir freuen uns über jede Rückmeldung, um die ThinHoc Plattform für Sie zum besten Endpointkonzept der Welt zu machen.</p><p><b>- Ihr ThinHoc-Team</b>',
    buttons: [
      {
        text: 'Zurück',
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: 'Beenden',
        action: tour.cancel
      }]
  });

  return tour;
}

function buildTestflightTourEN() {
  const tour = vue.$shepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
      classes: 'shadow-md bg-purple-dark',
      scrollTo: false,
    }
  });

  const standardButtons = {
    buttons: [
      {
        text: 'Back',
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: 'Next',
        action: tour.next
      }]
  }

  tour.addStep({
    title: "Home Screen",
    text: "<p>The Home Screen is your users' main point of interfacing with the system.</p><p>All the features and important information can be accessed from here.</p>",
    buttons: [
      {
        text: 'Next',
        action: tour.next
      }
    ]
  });

  tour.addStep({
    title: "Home Screen",
    text: 'The Home Screen consists of three main parts:<b><br/>- App Screen<br/>- Info Section<br/>- Status Bar</b>',
    ...standardButtons
  });

  tour.addStep({
    title: "App Screen",
    text: '<p>The App Screen displays all apps and features that you have deployed to your users.</p><p>This could be, for example, RDP Sessions, Web-based applications or one of the many configurable <b>ThinHoc Apps</b>.</p>',
    attachTo: {
      element: '#AppSelector',
      on: 'top'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Info Section",
    text: "<p>Info Section is a real-time communication channel to your users.</p><p>You could display information such as planned maintenance announcements, service outage notices, or general useful information like your helpdesk hotline phone number or link collection to your Intranet.</p><p>That way, your users stay on top of what's happening in your IT and you can focus on your important tasks.</p>",
    attachTo: {
      element: '#InfoSection',
      on: 'top'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar",
    text: '<p>The status Bar shows the health status of all your services as well as the most pertinent information about the system in one place.</p><p>That way, your helpdesk hotline will have a far easier job collecting all necessary info for efficient diagnosis of many classes of issues via phone.</p>',
    attachTo: {
      element: '#StatusBar',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Profile",
    text: '<p>Here you can see the currently active profile.</p><p>Profiles are a concept that allows different feature and application sets to be deployed for different tasks or different groups of users, depending on your needs.</p>',
    attachTo: {
      element: '#StatusBarProfile',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Machine ID",
    text: '<p>The Machine ID reliably identifies a machine and can facilitate remote support requests.</p>',
    attachTo: {
      element: '#StatusBarMachineID',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Status Bar - Network Overview",
    text: '<p>The Network Overview shows at a glance if the network connection and services are up and running. You can configure the servers to watch in the settings.</p>',
    attachTo: {
      element: '#StatusBarNetworkStatus',
      on: 'left'
    },
    ...standardButtons
  });

  tour.addStep({
    title: "Thank you!",
    text: '<p>We hope to have provided you a meaningful overview about the ThinHoc featureset.</p><p>Please find all additional information about our hardware and software at <a href="https://www.thinhoc.com" target="_blank">www.thinhoc.com</a>.</p><p>Please feel free to contact us in case of any questions or feature requests. We are always looking forward to feedback for us to create the world\'s best Endpoint Productivity platform for you.</p><p><b>Sincerely yours, The ThinHoc Team</b></p>',
    buttons: [
      {
        text: 'Back',
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: 'Finish',
        action: tour.cancel
      }]
  });

  return tour;
}

const store = new Vuex.Store({
  getters: {
    CMDemoActive(state) {
      try {
        return vue.$auth.user.email == 'demo@thinhoc.com';
      } catch(e) {}
      return false;
    },
    testflightTour(state) {
      if (state.language === 'DE') {
        return buildTestflightTourDE();
      } else {
        return buildTestflightTourEN();
      }
    },
    onlineStatusIndicators(state) {
      var ret = {}

      // Local (physical) network connection
      switch (state.onlineStatus.network) {
        case 'ok':
          ret.network = {
            color: 'green',
            icon: 'mdi-lan-check'
          }
          break;
        case 'error':
          ret.network = {
            color: 'red',
            icon: 'mdi-lan-disconnect'
          }
          break;
        case 'unkown':
        default:
          ret.network = {
            color: 'orange',
            icon: 'mdi-lan-pending'
          }
          break;
      }
      // Nameserver
      ret.dns = { icon: 'mdi-dns-outline' };
      switch (state.onlineStatus.dns) {
        case 'ok':
          ret.dns.color = 'green';
          break;
        case 'error':
          ret.dns.color = 'red';
          break;
        case 'unkown':
        default:
          ret.dns.color = 'orange';
          break;
      }
      // Internet connection
      switch (state.onlineStatus.internet) {
        case 'ok':
          ret.internet = {
            color: 'green',
            icon: 'mdi-web-check'
          }
          break;
        case 'error':
          ret.internet = {
            color: 'red',
            icon: 'mdi-web-off'
          }
          break;
        case 'unkown':
        default:
          ret.internet = {
            color: 'orange',
            icon: 'mdi-web-clock'
          }
          break;
      }
      // ThinHoc cloud connection
      switch (state.onlineStatus.cloud) {
        case 'ok':
          ret.cloud = {
            color: 'green',
            icon: 'mdi-cloud-check-outline'
          }
          break;
        case 'error':
          ret.cloud = {
            color: 'red',
            icon: 'mdi-cloud-off-outline'
          }
          break;
        case 'unkown':
        default:
          ret.cloud = {
            color: 'orange',
            icon: 'mdi-cloud-outline'
          }
          break;
      }

      return ret;
    }
  },
  state: {
    language: 'EN',
    environment: 'web',
    onlineStatus: {
      "network": "unknown",
      "internet": "unknown",
      "dns": "unknown",
      "cloud": "unknown"
    },
    onlineStatusInterval: null,
    settingsActiveForm: "customization",
    editor: "<p>Type your message here!</p>"
  },
  mutations: {
    setEnvironment(state, newEnv) {
      console.debug('(store) Setting environment to ' + newEnv)
      state.environment = newEnv
    }
  },
  actions: {
  },
  modules: {
    api,
    center,
    locale,
    background,
    mirror,
    tours
  }
})

/*
 * Sentry is included for local ThinHoc devices on beta & release channel as well as the production cloud build
 */
async function sentry_init() {
  console.debug('sentry_init() called')
  Sentry.init({
    Vue,
    dsn: "https://e0c0026d00e24f28abedbeff6fc5b4fe@o1239072.ingest.sentry.io/6390204",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(vue.$router),
        tracingOrigins: ["localhost", "config.thinhoc.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });


  Sentry.setTag('environment', store.state.environment)
  if(typeof pywebview !== "undefined") {
    //Add tag to Sentry
    Sentry.setTag('machine_id', await (pywebview.api.get_machine_id()))
    //Add release channel to Sentry
    Sentry.setTag('release_channel', await (pywebview.api.get_release_channel()))
  }
}

// Init sentry on public cloud build
if(location.hostname === 'config.thinhoc.com') sentry_init();

async function prepareStore() {
  store.commit('setEnvironment', 'center')

  //Load as soon as available
  store.dispatch('center/machineConfig/load')
  store.dispatch('center/machineContext/load')

  // Init sentry on beta & release builds
  if (['beta', 'release'].includes(await pywebview.api.get_release_channel())) {
    sentry_init();
  }
}

//Load config from pywebview api
if (typeof pywebview === "undefined") {
  window.addEventListener('pywebviewready', prepareStore)
} else {
  prepareStore();
}

setInterval(
    async () => {
      if (typeof pywebview === "undefined") return;
      console.debug('store[index] online status update')
      store.state.onlineStatus = await pywebview.api.get_online_status()
    },
    2500
);

/*
FOR DEBUG ONLY
*/
window.store = store;


export default store;
