<template>
  <div>
    <div class="dialog-shadow"></div>
    <v-card class="dialog-confirm" min-width="500px" max-width="75vw">
      <v-card-title>{{ title }}</v-card-title>
      <div class="dialog-body">
        <p>{{ content }}</p>
        <v-text-field v-model="value" />
      </div>
      <v-card-actions>
        <v-btn color="primary" @click="$close(value)">OK</v-btn>
        <v-btn color="warning" size="mini" @click="$close(false)">{{ $t('global.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: ''  
    }
  }
}
</script>
<style scoped>
.dialog-shadow {
  z-index: 300;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
}
.dialog-confirm {
  z-index: 301;
  padding: 16px 32px 16px 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>