
function buildCMDemoTour() {
  const tour = vue.$shepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      },
      classes: 'shadow-md bg-purple-dark',
      scrollTo: false,
    }
  });

  const standardButtons = {
    buttons: [
      {
        text: i18n.t('global.back'),
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: i18n.t('global.continue'),
        action: tour.next
      }]
  }

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-1.title'),//"Willkommen zum ThinHoc Cloud Management",
    text: i18n.t('tours.CMDemo.step-1.text'),
    buttons: [
      {
        text: i18n.t('global.continue'),
        action: tour.next
      }
    ]
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-2.title'),
    text: i18n.t('tours.CMDemo.step-2.text'),
    attachTo: {
      element: '#TourOrgSelector',
      on: 'top'
    },
    canClickTarget: false,
    ...standardButtons
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-3.title'),
    text: i18n.t('tours.CMDemo.step-3.text'),
    attachTo: {
      element: '#TourOrgSelectorSelectExisting',
      on: 'top'
    },
    /* Continue once Org has been selected */
    advanceOn: {selector: 'body', event: 'tour-next'},
    buttons: [
      {
        text: i18n.t('global.back'),
        classes: 'shepherd-button-secondary',
        action: tour.back
      }
    ]
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-4.title'),
    text: i18n.t('tours.CMDemo.step-4.text'),
    attachTo: {
      element: '#management-nav',
      on: 'right'
    },
    canClickTarget: false,
    buttons: [
      {
        text: i18n.t('global.continue'),
        action: tour.next
      }
    ]
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-5.title'),
    text: i18n.t('tours.CMDemo.step-5.text'),
    attachTo: {
      element: '#TourManagementNavProfiles',
      on: 'right'
    },
    canClickTarget: false,
    buttons: [
      {
        text: i18n.t('global.back'),
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: i18n.t('global.continue'),
        async action() {
          try {
          await vue.$router.push('/manage/machines/deployment/smartconference')
          } catch(e) {}
          tour.next()
        }
      }]
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-6.title'),
    text: i18n.t('tours.CMDemo.step-6.text'),
    attachTo: {
      element: '#TourManagementMachines',
      on: 'top'
    },
    ...standardButtons
  });

  tour.addStep({
    title: i18n.t('tours.CMDemo.step-7.title'),
    text: i18n.t('tours.CMDemo.step-7.text'),
    buttons: [
      {
        text: i18n.t('global.back'),
        classes: 'shepherd-button-secondary',
        action: tour.back
      },
      {
        text: i18n.t('global.leave'),
        action: tour.cancel
      }]
  });

  return tour;
}

const state = {

}

const getters = {
  CMDemoTour(state) {
    return buildCMDemoTour();
  }
}

const mutations = {
}

const actions = {
}

const modules = {
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}