import { importAll } from "../util";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function between(x, min, max) {
  return x >= min && x <= max;
}

/* Tells webpack to pack all background images */
importAll(require.context('../assets/img/backgrounds-resized', true))

const state = {
  background_images: {
    morning: require.context('../assets/img/backgrounds-resized/morning', false, /-1920\.jpg$/).keys(),
    day: require.context('../assets/img/backgrounds-resized/day', false, /-1920\.jpg$/).keys(),
    evening: require.context('../assets/img/backgrounds-resized/evening', false, /-1920\.jpg$/).keys(),
    night: require.context('../assets/img/backgrounds-resized/night', false, /-1920\.jpg$/).keys(),
  }
}

const getters = {
  currentBackground(state) {
    const hour = new Date().getHours();
    var time = 'day';
    switch (true) {
      case (between(hour, 0, 5)):
      case (between(hour, 21, 24)):
        time = 'night'        
        break;
      case (between(hour, 6, 9)):
        time = 'morning'
        break;
      default:
      case (between(hour, 10, 17)):
        time = 'day'
        break;
      case (between(hour, 18, 20)):
        time = 'evening';
        break;
    }
    const unixTime = Math.round(Date.now() / 1000);
    const unixTimeDay = unixTime - unixTime % (24 * 60 * 60);
    const len = state.background_images[time].length;
    const key = state.background_images[time][(unixTimeDay + hour) % len]
    return require('../assets/img/backgrounds-resized/' + time + '/' + key.slice(2));
  }
}

const mutations = {
}

const actions = {
}

const modules = {
  
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
  modules
}