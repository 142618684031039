
const state = {
  showNotificationSnackbar: false,
  notification: '',
  sipAccountsLength: 0,
  incomingCall: false,
  incomingCallPartner: "UNKNOWN",
  inActiveCall: false,
  callPartner: "UNKNOWN",
  callStatus: "DISCONNECTED",
  micMuted: false,
  dialerVisible: false,
  state: {
    initialized: false
  }
}

const getters = {
  micMuted(state) {
    return state.mic_muted;
  },
  sipAccounts(state) {
    return state.state.accounts;
  },
  sipInitialized(state) {
    return state.state.initialized;
  }
}

const mutations = {
  setState(state, sipState) {
    state.state = sipState

    state.state.initialized = true;

    /* Default values */
    state.incomingCall = false;
    state.incomingCallPartner = "UNKNOWN";
    state.callPartner = "UNKNOWN";
    state.inActiveCall = false;
    state.callStatus = "DISCONNECTED";

    state.sipAccountsLength = Object.keys(sipState.accounts).length;

    for (const [key, call] of Object.entries(sipState.active_calls)) {
      if(call.direction == 'incoming' && call.state == 'INCOMING') {
        state.incomingCall = true;
        state.incomingCallPartner = call.peerdisplayname;
      }
      if(call.state == 'RINGING' || call.state == 'ANSWERED' || call.state == 'ESTABLISHED') {
        state.inActiveCall = true;
        state.callPartner = call.peerdisplayname;
        state.callStatus = call.state;
      }
    }
    state.micMuted = sipState.mic_muted;
  },
  setIncomingCall(state, val) {
    state.incomingCall = val;
  },
  setCallStatus(state, val) {
    state.setCallStatus = val;
  },
  setDialerVisible(state, val) {
    state.dialerVisible = val
  },
  showNotification(state, payload) {
    state.notification = payload;
    state.showNotificationSnackbar = true;
    setTimeout(() => {
      state.showNotificationSnackbar = false;
    }, 2000);
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}