<template>
  <v-app id="app">
    <router-view />
    <DebugDialog />
    <dialogs-wrapper transition-name="fade"></dialogs-wrapper>
  </v-app>
</template>

<script>
import DebugDialog from './components/DebugDialog.vue';
export default {
  components: { DebugDialog },
  name: "App",
};
</script>

<style>
#app {
  font-family: Assistant, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}

html,
body {
  position: fixed;
  overflow: hidden !important;
  height: 100vh;
  width: 100vw;
}

/* Tour styles */
.shepherd-content {
  font-family: Assistant, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
}

.shepherd-header .shepherd-title {
  font-weight: bold;
}

.shepherd-text > p {
  margin-bottom: 10px !important;
}

.shepherd-footer {

}

</style>
