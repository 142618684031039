import Vue from 'vue'
import './plugins/composition-api'

import App from './App.vue'
import router from './router'
import { Auth0Plugin } from "./auth";
import VueShepherd from 'vue-shepherd';

import { auth0_domain, auth0_clientId } from "../auth_config.json";

import '@mdi/font/css/materialdesignicons.css'
import "./lib/vuetify.css"
import 'shepherd.js/dist/css/shepherd.css';
import VAnimateCss from 'v-animate-css';

import vuetify from '@/plugins/vuetify' // path to vuetify export

import VueTelInputVuetify from 'vue-tel-input-vuetify';
Vue.use(VueTelInputVuetify, {
  vuetify,
});

import i18n from './i18n' // Import before store
import store from './store'
import { importAll } from './util'

/* Tells webpack to pack all config related files */
importAll(require.context('@/assets/config', true))


Vue.use(VAnimateCss);

Vue.use(VueShepherd);

import * as ModalDialogs from 'vue-modal-dialogs'
Vue.use(ModalDialogs)   

Vue.use(Auth0Plugin, {
  domain: auth0_domain,
  clientId: auth0_clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false

/*Sentry.init({
  Vue,
  dsn: "https://e0c0026d00e24f28abedbeff6fc5b4fe@o1239072.ingest.sentry.io/6390204",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "config.thinhoc.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

// Set vuetify locale
function updateVuetifyLocale(ev) {
  app.$vuetify.lang.current = ev.locale.vuetify_lang;
}
window.addEventListener('i18n-locale-changed', updateVuetifyLocale);
updateVuetifyLocale(store.state.locale);

/*
FOR DEBUG ONLY
*/
window.vue = app;