import machine from "./machine"
import organization from "./organization"
import profiles from "./profiles"
import session from "./session"
import user from "./user"

const state = {
  cloudManagementHostnames: ['config.thinhoc.com', 'config-beta.thinhoc.com', 'config-nightly.thinhoc.com'],
  isCloudVersion: ['config.thinhoc.com', 'config-beta.thinhoc.com', 'config-nightly.thinhoc.com'].includes(location.hostname),
}

const getters = {

}

const actions = {

}

const mutations = {

}

const modules = {
  machine,
  organization,
  profiles,
  session,
  user
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}