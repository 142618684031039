/* OPTIMIZATION
 * Re-build createAjv function from jsonforms in order not to load the whole JFs stack here
 * Needs to be in sync with JFs implementation
 */
// Replaces: import { createAjv } from '../../../lib/jsonforms-vue2-vuetify';

import Ajv from 'ajv';
import addFormats from 'ajv-formats';

var createAjv = function createAjv(options) {
    const ajv = new Ajv({
        allErrors: true,
        verbose: true,
        strict: false,
        ...options
      });
    addFormats(ajv);
    ajv.addFormat('password', function (_) {
      return true;
    });
    ajv.addFormat('color', function (_) {
      return true;
    });
    return ajv;
  };

export const ajv = createAjv({ useDefaults: "empty" })