import dialogs from '../../../components/dialogs'

import { OrganizationControllerApi, PublicOrganizationControllerApi } from '../../../lib/thinhoc-api'

import { authenticateAPI, loadingState } from './utils';
import store from '../..';

export function mgmtTierLevel(tier) {
  if(tier == 'free') {
    return 1;
  } else if(tier == 'pro') {
    return 2;
  } else {
    return NaN;
  }
}


const state = {
  availableOrganizations: [],
  availableOrganizationsState: loadingState.IDLE,
  activeOrganization: {},
  activeOrganizationId: null,
  activeOrganizationState: loadingState.IDLE,
  errorDescription: "",
  invitations: {
    data: [],
    state: loadingState.IDLE,
    error: null
  }
}

const getters = {
  cloudManagementTier(state) {
    return state.activeOrganization.mgmtTier || 'free';
  }
}

const mutations = {
  setActiveOrganization(state, org) {
    console.debug('(store[api/organization]) setActiveOrganization orgId=' + org.id)
    state.activeOrganizationId = org.id
    state.activeOrganization = org
    //Reload profiles
    store.dispatch('api/profiles/loadProfilesList')
  },
  setActiveOrganizationId(state, orgId) {
    console.debug('(store[api/organization]) setActiveOrganizationId orgId=' + orgId)
    state.activeOrganizationId = orgId
  },
  setAvailableOrganizations(state, orgs) {
    if(orgs) orgs.sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
    state.availableOrganizations = orgs;
  },
  setState(state, _state) {
    state.activeOrganizationState = _state
  },
  setError(state, err) {
    console.error('(session[api/organizaiton]) setError: ' + err.message)
    state.activeOrganizationState = loadingState.ERROR
    state.errorDescription = err.message
  }
}

const actions = {
  async createOrganization(ctx, name) {
    return new Promise(async (resolve, reject) => {
      const api = await authenticateAPI(OrganizationControllerApi)
      api.organizationControllerCreate({ name: name, owner: window.vue.$auth.user.email})
        .then((res) => {
          console.log(res)
          ctx.dispatch('loadActiveOrganization', res.data.id)
          ctx.dispatch('loadAvailableOrganizations')
          resolve(res.data);
        })
        .catch((err) => {
          dialogs.showAPIError('Failed to create organization! Try again later.', err);
          reject(err);
        })
    });
  },
  async loadAvailableOrganizations(ctx) {
    const api = await authenticateAPI(PublicOrganizationControllerApi)
    ctx.state.availableOrganizationsState = loadingState.LOADING
    api.publicOrganizationControllerFind()
      .then((res) => {
        console.log(res)
        ctx.commit('setAvailableOrganizations', res.data)
        ctx.state.availableOrganizationsState = loadingState.LOADED
      })
      .catch((err) => {
        ctx.state.availableOrganizationsState = loadingState.ERROR
      })
  },
  async loadActiveOrganization(ctx, orgId) {
    ctx.commit('setState', loadingState.orgId)
    console.log("Setting active org to id=" + orgId)
    const api = await authenticateAPI(OrganizationControllerApi)
    api.organizationControllerFindById(orgId)
      .then((res) => {
        console.log(res)
        ctx.commit('setActiveOrganization', res.data)
        ctx.commit('setState', loadingState.LOADED)
      })
      .catch((err) => {
        console.warn(err)
        ctx.state.errorDescription = err.message
        ctx.commit('setState', loadingState.ERROR)
      })
  },
  async saveOrganization(ctx) {
    return new Promise(async (resolve, reject) => {
      const api = await authenticateAPI(OrganizationControllerApi)
      const payload = ctx.state.activeOrganization;
      payload.mgmtTier = undefined; //mgmtTier needs to be patched via /{id}/mgmtTier
      api.organizationControllerUpdateById(ctx.state.activeOrganizationId, JSON.stringify(payload))
        .then((res) => {
          console.log(res)
          ctx.dispatch('loadAvailableOrganizations')
          resolve(res);
        })
        .catch((err) => {
          console.warn(err)
          reject(err);
        })
    });
  },
  async deleteMember(ctx, member) {
    const api = await authenticateAPI(OrganizationControllerApi)
    ctx.state.activeOrganization.members = ctx.state.activeOrganization.members.filter((el) => el != member);
    api.organizationControllerDeleteMember(ctx.state.activeOrganizationId, { email: member})
      .then((res) => {
        console.debug(res);
        ctx.dispatch('loadActiveOrganization')
      })
      .catch((err) => {
        console.warn(err);
      })
  },
  async leaveOrganization(ctx) {
    const api = await authenticateAPI(OrganizationControllerApi);
    api.organizationControllerLeaveOrganization(ctx.state.activeOrganizationId)
      .then((res) => {
        console.debug(res);

      })
      .catch((err) => {
        console.warn(err);
      })
  },
  async loadAuthSettings(ctx) {
    const api = await authenticateAPI(OrganizationControllerApi)
    const res = await api.organizationControllerFindAuthSettingsById(ctx.state.activeOrganizationId)
    return res.data;
  },
  async saveAuthSettings(ctx, payload) {
    const api = await authenticateAPI(OrganizationControllerApi)
    return await api.organizationControllerReplaceAuthSettingsById(ctx.state.activeOrganizationId, payload)
  },
  async inviteUser(ctx, email) {
    return new Promise(async (resolve, reject) => {
      const api = await authenticateAPI(OrganizationControllerApi);
      api.organizationControllerInviteUser(ctx.state.activeOrganizationId, {email})
        .then((res) => {
          dialogs.inform("User invitation successful", `Please ask <b>${email}</b> to log in to ThinHoc Cloud Management and accept the invitation.`)
          resolve(res.data);
        })
        .catch((err) => {
          dialogs.showAPIError("Failed to invite user", err);
          reject(err);
        })
    });
  },
  async loadInvitations(ctx) {
    ctx.state.invitations.state = loadingState.LOADING
    const api = await authenticateAPI(OrganizationControllerApi)
    api.organizationControllerGetInvitations()
      .then((res) => {
        console.log(res)
        ctx.state.invitations.data = res.data
        ctx.state.invitations.error = null
        ctx.state.invitations.state = loadingState.LOADED
      })
      .catch((err) => {
        console.warn(err)
        ctx.state.invitations.error = err.message
        ctx.state.invitations.state = loadingState.ERROR
      })
  },
  async acceptInvitation(ctx, invitation) {
    return new Promise(async (resolve, reject) => {
    const api = await authenticateAPI(OrganizationControllerApi)
    api.organizationControllerAcceptInvitation(invitation.organization.id)
      .then(async (res) => {
        dialogs.inform("", "Successfully accepted invitation")
        await ctx.dispatch('loadAvailableOrganizations')
        await ctx.dispatch('loadActiveOrganization', invitation.organization.id)
      })
      .catch((err) => {
        dialogs.showAPIError("Failed to accept invitation", err);
        reject(err);
      })
    });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
