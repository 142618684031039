<template>
  <div>
    <div class="dialog-shadow"></div>
    <v-card :loading="true" class="dialog-waitfor" min-width="500px" max-width="75vw">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-if="content" class="dialog-body">
        <p class="text-left" v-html="content"></p>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  async mounted() {
    try {
      // "promise" is the third argument given to WaitFor dialog
      // We are waiting for its completion and return the result ...
      this.$close(await this.promise);
    } catch(err) {
      // ... or the error during execution
      this.$error(err)
    }
  }
})
</script>

<style scoped>
.dialog-shadow {
  z-index: 300;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
}
.dialog-waitfor {
  z-index: 301;
  padding: 0px 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>