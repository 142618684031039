
const state = {
  userConfigState: "EMPTY",
  userConfig: {
    last_machine_id: 'SYSTEM',
    testflight: false
  },
  errorDescription: null
}

const getters = {
  shortMachineID(state) {
    return state.userConfig.last_machine_id.slice(0, 6).toUpperCase()
  }
}

const mutations = {
  setConfig(state, userConfig) {
    state.userConfig = userConfig
    state.errorDescription = null
  }
}

const actions = {
  async loadUserConfig(ctx) {
    //Timeout
    setTimeout(() => {
      if(ctx.state.sessionState === 'LOADING') {
        ctx.state.errorDescription = "Timeout while loading user config!"
        ctx.state.userConfigState = 'ERROR'
      }
    }, 3000);

    state.userConfigState = 'LOADING'
    if(typeof pywebview !== 'undefined') {
      await pywebview.api.load_user_config()
        .then((res) => {
          console.debug('(center/userConfig) Config available!')
          state.userConfigState = 'LOADED'
          ctx.commit('setConfig', res)
        })
        .catch((err) => {
          console.warn("Failed to load userConfig!")
          state.userConfigState = 'ERROR'
          ctx.state.errorDescription = err.message
        })
    }
  },
  async setMachineToken(ctx, token) {
    if(typeof pywebview !== 'undefined') {
      await pywebview.api.set_machine_token(token)
      ctx.dispatch('loadUserConfig')
    }
  },
  async disableFBW(ctx) {
    if(typeof pywebview !== 'undefined') {
      console.log('Disabling FBW')
      await pywebview.api.set_user_config_value('firstboot', false)
      ctx.dispatch('loadUserConfig')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}