import { create } from 'vue-modal-dialogs'

import APIError from './APIError.vue'
import Ask from './Ask.vue'
import Confirm from './Confirm.vue'
import Inform from './Inform.vue'
import WaitFor from './WaitFor.vue'

const showAPIError = create(APIError, 'content', 'error')
const ask = create(Ask, 'title', 'content')
const confirm = create(Confirm, 'title', 'content')
const inform = create(Inform, 'title', 'content')
const waitFor = create(WaitFor, 'title', 'content', 'promise')

export default {
    ask,
    confirm,
    inform,
    showAPIError,
    waitFor,
}