import moment from "moment";

const LAST_LOCALE_KEY = 'thoc-locale';

const availableLocales = {
}

function addLocale(locale) {
  const key = `${locale.language['iso-639-2']}_${locale.territory['iso-3166-a2']}`
  availableLocales[key] = locale;
  availableLocales[key].id = key;
}
/* Install all default locales */
/* deu_DE */
addLocale({
  'bcp47': 'de-DE',
  'vuetify_lang': 'de',
  'language': {
    'iso-639-2': 'deu',
    'iso-639-2-fallback': 'eng',
    'name': 'Deutsch',
    'name_int': 'German'
  },
  'territory': {
    'iso-3166-a2': 'DE',
    'name': 'Deutschland',
    'name_int': 'Germany'
  }
})

/* eng_UK */
addLocale({
  'bcp47': 'en-GB',
  'vuetify_lang': 'en',
  'language': {
    'iso-639-2': 'eng',
    'iso-639-2-fallback': 'eng',
    'name': 'English',
    'name_int': 'English'
  },
  'territory': {
    'iso-3166-a2': 'GB',
    'name': 'United Kingdom',
    'name_int': 'United Kingdom'
  }
})

/* gsw_CH */
addLocale({
  'bcp47': 'de-CH',
  'vuetify_lang': 'de',
  'language': {
    'iso-639-2': 'gsw',
    'iso-639-2-fallback': 'deu',
    'name': 'Deutsch (CH)',
    'name_int': 'Swiss German'
  },
  'territory': {
    'iso-3166-a2': 'CH',
    'name': 'Schweiz',
    'name_int': 'Switzerland'
  }
})

/* rus_RU */
addLocale({
  'bcp47': 'ru-RU',
  'vuetify_lang': 'ru',
  'language': {
    'iso-639-2': 'rus',
    'iso-639-2-fallback': 'eng',
    'name': 'русский',
    'name_int': 'Russian'
  },
  'territory': {
    'iso-3166-a2': 'RU',
    'name': 'Россия',
    'name_int': 'Russia'
  }
})

const state = {
  availableLocales: availableLocales,
  locale: availableLocales['eng_UK'],
}

const getters = {
  
}

const mutations = {
  setLocale(state, localeID) {
    if (state.availableLocales[localeID]) {
      console.warn(`Setting locale to "${localeID}`)
      state.locale = state.availableLocales[localeID]
      moment.locale(state.locale['bcp47'])
      // Save last set locale to localStorage
      localStorage.setItem(LAST_LOCALE_KEY, localeID);
      const ev = new Event("i18n-locale-changed");
      ev.locale = state.locale;
      window.dispatchEvent(ev);
    } else {
      console.warn(`Tried to switch to unknown locale "${localeID}!`)
    }
  }
}

const actions = {
}

const modules = {
  
}

// Load last locale from localStorage or use German as default
mutations.setLocale(state, localStorage.getItem(LAST_LOCALE_KEY) || 'deu_DE');

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}