import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const vueI18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})

window.addEventListener('i18n-locale-changed', function (ev) {
  const locale = ev.locale;
  console.log(`Setting language to "${locale.language['iso-639-2']}" and fallback to "${locale.language['iso-639-2-fallback']}"`);
  vueI18n.locale = locale.language['iso-639-2']
  vueI18n.fallbackLocale = locale.language['iso-639-2-fallback']
});

/* Export to global scope */
window.i18n = vueI18n;

export default vueI18n;
