// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import { VCard, VCardActions, VCardTitle, VFileInput, VImg, VSelect, VTextField } from 'vuetify/lib'
import { en, de, ru } from 'vuetify/lib/locale'

Vue.use(Vuetify, {components: {VCard, VCardActions, VCardTitle, VFileInput, VImg, VSelect, VTextField}})

const opts = {
    lang: {
        locales: {
            en, de, ru
        }
    }
}

export default new Vuetify(opts)