<template>
  <div>
    <v-dialog
      v-if="showDebugDialog"
      id="dialog"
      scrollable
      v-model="showDebugDialog"
      min-width="500px"
      max-width="70vw"
      @click="open"
    >
      <v-card dark class="card-form">
        <v-card-title>ThinHoc Debug Dialog</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="code" clearable label="Code" @keydown.enter="handleEnter"/>
              <v-textarea
                v-model="result"
                :error="error"
                readonly
                label="Result"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange darken-1" @click="close"> Close </v-btn>
          <v-btn color="green" @click="execute"> Execute </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DebugDialog",
  components: {},
  computed: {},
  data() {
    return {
      keypress_cnt_U: 0,
      keypress_cnt_K: 0,
      code: "",
      result: undefined,
      error: false,
      showDebugDialog: false,
    };
  },
  methods: {
    async open() {
      console.debug("open() called");
    },

    close() {
      console.debug("close() called");
      this.showDebugDialog = false;
    },
    handleEnter(e) {
        if(e.ctrlKey) {
            e.preventDefault();
            this.execute();
        }
    },
    async execute() {
      try {
        this.error = false;
        var segments = this.code.split('\n')
        segments.push('return ' + segments.pop());
        const patched = segments.join('; ');
        const wrapped = '(async () => {'+patched+'})'
        console.log(wrapped);
        const fun = eval(wrapped);
        this.result = await fun();
      } catch (e) {
        this.error = true;
        this.result = e.toString();
      }
    },
  },
  mounted() {
    console.debug('Quickly pressing Strg+U three time opens debug menu.');

    this._keyListener = function (e) {
      //Open DebugDialog when Strg + U have been pressed three time
      if (e.key === "U" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if(this.keypress_cnt_U == 0) setTimeout(() => { this.keypress_cnt_U = 0}, 1000);
        this.keypress_cnt_U += 1;
        if(this.keypress_cnt_U >= 3) this.showDebugDialog = true;
      }

      //Open terminal when Strg + K have been pressed five time
      if (e.key === "K" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if(this.keypress_cnt_K == 0) setTimeout(() => { this.keypress_cnt_K = 0}, 2000);
        this.keypress_cnt_K += 1;
        if(this.keypress_cnt_K >= 5) pywebview.api.open_terminal();
      }
    }

    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener);
  }
};
</script>

<style scoped>
#dialog {
}
</style>