
import { MachineControllerApi, PublicOrganizationMachineControllerApi } from '../../../lib/thinhoc-api'
import moment from 'moment'
import { authenticateAPI, loadingState } from './utils';

const state = {
  machineList: [],
  machineListState: "EMPTY",
  registration: {
    status: loadingState.IDLE,
    errorDescription: null
  }
}

const getters = {
}

const mutations = {
  setMachineList(state, machineList) {
    state.machineList = machineList
  },
  setMachineListState(state, machineListState) {
    state.machineListState = machineListState
  },
}

const actions = {
  async register(ctx, {profileId, organizationId, deployment, name}) {
    //Timeout
    setTimeout(() => {
      if(ctx.state.registration.status === loadingState.LOADING) {
        console.error("Registration Timeout!");
        ctx.state.registration.status = loadingState.ERROR;
        ctx.state.registration.errorDescription = this.$t("fbw.error.timeout");
      }
    }, 45 * 1000);

    ctx.state.registration.status = loadingState.LOADING;
    const api = await authenticateAPI(MachineControllerApi)

    if(ctx.rootState.environment !== 'center') {
      console.error('BUG: Cannot register in environment other than "center"');
      return;
    }

    const payload = {
      id: await pywebview.api.get_machine_id(),
      machineToken: await pywebview.api.get_machine_token(),
      organizationId: organizationId.toString(),
      profileId: profileId.toString(),
      deployment: deployment,
      name: name,
    };

    api.machineControllerRegister(payload['id'], payload)
      .then((res) => {
        ctx.state.registration.status = loadingState.LOADED;
        console.log(res)
      })
      .catch((err) => {
        console.warn(err)
        ctx.state.registration.status = loadingState.ERROR;
        ctx.state.registration.errorDescription = err.toString();
        throw err;
      })
  },
  async loadMachineList(ctx) {
    ctx.commit('setMachineListState', 'LOADING')
    if (!ctx.rootState.api.organization.activeOrganizationId) {
      console.warn("Active Organization not set!")
    }
    const api = await authenticateAPI(PublicOrganizationMachineControllerApi)
    api.publicOrganizationMachineControllerFindRuntime(ctx.rootState.api.organization.activeOrganizationId)
      .then(async (res) => {
        console.log(res)

        const processed = []
        await res.data.forEach(raw => {
          const machine = JSON.parse(JSON.stringify(raw))
          machine.shortId = raw.id.slice(0, 6).toUpperCase();
          machine.id = raw.id;
          //ToDo: Set locale
          if(machine.uptime > 0) {
            machine.runningSince = moment(new Date(Date.now()-machine.uptime*1000)).fromNow()
          } else {
            machine.runningSince = "---"
          }
          machine.messages = []
          if(machine.rebootNeeded) {
            machine.messages.push(vue.$i18n.t('management.machines.messages.reboot-needed'))
          }

          processed.push(machine)
        });

        ctx.commit('setMachineList', processed)
        ctx.commit('setMachineListState', 'LOADED')
      })
      .catch((err) => {
        console.warn(err)
        ctx.commit('setMachineListState', 'ERROR')
      })
  },
  async rename(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    await api.machineControllerRenameById(payload.id, {name: payload.name})
    ctx.dispatch('loadMachineList');
  },
  async switchProfile(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    if(!payload.id && ctx.rootState.environment === 'center') {
      payload.id = await pywebview.api.get_machine_id();
    }
    console.log(ctx)
    console.log(payload)
    await api.machineControllerPatchProfileById(payload.id, {profileId: payload.profileId})
    ctx.dispatch('loadMachineList');
  },
  async notify(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    api.machineControllerNotifyById(payload.id, {msg: payload.msg})
  },
  async poweroff(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    api.machineControllerPoweroffById(payload.id)
  },
  async reboot(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    api.machineControllerRebootById(payload.id)
  },
  async triggerSoftwareUpdate(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    api.machineControllerTriggerSoftwareUpdateById(payload.id);
  },
  async postCommandRequest(ctx, payload) {
    const api = await authenticateAPI(MachineControllerApi);
    return await api.machineControllerPostCommandRequest(payload.id, payload.wait, payload.command);
  }
}




export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
