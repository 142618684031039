import dialogs from "@/components/dialogs";
import store from "@/store"

const state = {
}

const getters = {

}

const actions = {
    openWhiteboard(ctx) {
      if(store.state.environment === 'center') {
        console.info(`Opening whitboard with OTC of "${ctx.rootState.mirror.otc}"`)
        pywebview.api.open_whiteboard(ctx.rootState.mirror.otc);
      } else {
        dialogs.inform('Whiteboard is only available on a ThinHoc device!')
      }
    },
    changeWhiteboardOTC(ctx, otc) {
      pywebview.api.change_whiteboard_otc(otc);
    }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}