import { authenticateAPI } from "../api/utils";
import { OrganizationControllerApi } from '../../../lib/thinhoc-api'

const state = {
  machineConfig: {
    machine_id: 'SYSTEM',
    firstboot: false,
    testflight: false
  },
  status: 'EMPTY',
  errorDescription: null
}

const getters = {
  shortMachineID(state) {
    return state.machineConfig.machine_id.slice(0, 6).toUpperCase();
  },
  machineID(state) {
    return state.machineConfig.machine_id;
  },
  deployment(state) {
    return state.machineConfig.deployment || 'vanilla';
  },
  machineConfigStatus(state) {
    return state.status;
  },
  isFirstboot(state) {
    return state.machineConfig.firstboot || false;
  },
  testflightEnabled(state) {
    return state.machineConfig.testflight;
  },
  currentProfileId(state) {
    return state.machineConfig.current_profile_id;
  },
  organizationId(state) {
    return state.machineConfig.organization_id;
  }
}

const mutations = {
  setConfig(state, config) {
    state.machineConfig = config
    state.errorDescription = null
  }
}

const actions = {
  async load(ctx) {
    if(state.status === 'LOADING') return; // Loading already in progress

    if(typeof pywebview !== 'undefined') {
      state.status = 'LOADING'
      //Timeout
      const timeout = setTimeout(() => {
        ctx.state.errorDescription = "Timeout while loading machine config!"
        ctx.state.status = 'ERROR'
      }, 3000);

      await pywebview.api.load_machine_config()
        .then((res) => {
          clearTimeout(timeout);
          state.status = 'LOADED'
          ctx.commit('setConfig', res)
          window.vue.$store.commit("api/organization/setActiveOrganizationId", res.organization_id, {root:true})
        })
        .catch((err) => {
          clearTimeout(timeout);
          console.warn("Failed to load machine config!")
          state.status = 'ERROR'
          ctx.state.errorDescription = err.message
        })
    } else {
      //Load once pywebview is ready
      window.addEventListener('pywebviewready', () => store.dispatch('center/machineConfig/load'));
    }
  }
}

async function verifyUserPermission() {
  if(typeof pywebview === 'undefined') return;

  /* Verify that the user is allowed to access this ThinHoc by requesting its organization */
  const api = await authenticateAPI(OrganizationControllerApi);
  const orgId = state.machineConfig.organization_id;
  if(!orgId) return; //Org ID not set
  api.organizationControllerFindById(orgId)
    .then(() => {
      console.info('The authenticated user may manage this device.')
    })
    .catch((err) => {
      if(err.response && err.response.status == 401) {
        /* User is not allowed to access this device; log-out and display warning! */
        window.vue.$auth.logout();
        console.error(JSON.stringify(err))
        alert('Your user is not allowed to manage this device!');
      }
      // Could not verify; try again
      setTimeout(() => {
        verifyUserPermission();
      }, 5000);
    });
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}