
const state = {
  promptResponses: {
  },
  authenticationComplete: false
}

const getters = {
 
}

const mutations = {
  setAuthenticationComplete(state, value) {
    state.authenticationComplete = value;
  }
}

const actions = {
  async autologin(ctx) {
    if (pywebview === undefined) return;
    console.info(`Logging in with autologin`)

    //Cancel previous authentication session
    await pywebview.api.ldm_cancel_authentication()
    const authPromise = new Promise(_authenticationHandlerPromise);
    await pywebview.api.ldm_authenticate_autologin();
    return authPromise;
  },
  async loginWithProvider(ctx, {provider, username, password}) {
    if (pywebview === undefined) return;

    ctx.state.promptResponses['provider'] = provider
    ctx.state.promptResponses['password'] = password

    console.info(`Logging in "${username} with provider "${provider}"`)

    this.authenticationComplete = false;
    //Cancel previous authentication session
    await pywebview.api.ldm_cancel_authentication()
    const authPromise = new Promise(_authenticationHandlerPromise);
    await pywebview.api.ldm_authenticate(username);
    return authPromise;
  },
}

function _authenticationHandlerPromise(resolve, reject) {
    /* Handle auth event */
  async function handleAuthenticationComplete(ev) {
    window.removeEventListener('ldm-authentication-complete', handleAuthenticationComplete);
    const isAuthenticated = await pywebview.api.ldm_is_authenticated();
    if(isAuthenticated) {
      console.log('Authenticated! Starting session')
      await pywebview.api.ldm_start_session('gnome') //ToDo Get session
      resolve(true);
    } else {
      reject(new Error(vue.$i18n.t('greeter.login-failed')));
    }
  }
  window.addEventListener('ldm-authentication-complete', handleAuthenticationComplete);
  /* Login timeout */
  setTimeout(
    () => {
      window.removeEventListener('ldm-authentication-complete', handleAuthenticationComplete);
      reject(new Error(vue.$i18n.t('greeter.login-timed-out')));
    },
    7.5 * 1000
  );
}

window.addEventListener('ldm-authentication-complete', async (ev) => {
  console.debug('Received event: "ldm-authentication-complete"');
  window.vue.$store.commit('center/lightDM/setAuthenticationComplete', true);
})

window.addEventListener('ldm-show-prompt', async (ev) => {
  var key = ev.detail.message;
  key = key.replace(/\W/g, '')
  key = key.toLowerCase()

  if(key in state.promptResponses) {
    console.debug(`Responding to prompt "${ev.detail.message}"`);
    await pywebview.api.ldm_respond(state.promptResponses[key]);
  } else {
    console.warn(`No response for prompt "${ev.detail.message}" defined!`)
  }
})

window.addEventListener('ldm-show-message', (ev) => {
  console.log(ev);
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}