'use strict';

import { Configuration } from "../../../lib/thinhoc-api";
import IdTokenVerifier from 'idtoken-verifier';
import { sleep } from '../../../util';

const apiAudienceOptions = {
  audience: 'https://api.thinhoc.com/v1/'
};
let verifier = new IdTokenVerifier({
  issuer: 'https://id.thinhoc.com/',
  audience: apiAudienceOptions.audience,
});
let loginSemaphore = false;
const apiTokenKeyInStorage = 'api-auth0-token';

// Checks if permission thinhoc-admin is set on token
export function isThinHocAdmin() {
  const accessToken = sessionStorage.getItem(apiTokenKeyInStorage)
  if (!accessToken) return false;
  const decoded = verifier.decode(accessToken);
  console.log(decoded);
  return (decoded.payload.permissions || []).includes('thinhoc-admin');
}

async function getNewToken() {
  let authPlugin = window.vue.$auth;
  loginSemaphore = true;

  await authPlugin.checkSession(
    apiAudienceOptions,
    (authResult) => {
      sessionStorage.setItem(apiTokenKeyInStorage, authResult.accessToken);
      loginSemaphore = false;
    },
    async (error) => {
      window.console.log('Failed to silently request API Token!');
      window.console.error(error);
      await window.vue.$router.push('/session/login');
      loginSemaphore = false;
    }
  );
  while(loginSemaphore) {
    //Login in progress wait
    await sleep(50);
  }
}

async function getAPIToken() {
  let token = sessionStorage.getItem(apiTokenKeyInStorage);
  verifier.verify(
    token,
    async (error, _) => {
      if (error) {
        await getNewToken();
      }
    }
  );
  while(loginSemaphore) {
    //Login in progress wait
    await sleep(50);
  }
  return sessionStorage.getItem(apiTokenKeyInStorage);
}

async function getAPIBase() {
  /* Always use demo API when demo@thinhoc.com is logged in */
  try {
    if(vue.$auth.user.email === 'demo@thinhoc.com') {
      return 'https://api-demo.thinhoc.com/v1/'; 
    }
  } catch(e) {}
  
  if(typeof pywebview !== 'undefined') {
    return await pywebview.api.get_api_base();
  } else if(location.hostname === 'config.thinhoc.com') {
    return 'https://api.thinhoc.com/v1/';
  } else if(location.hostname === 'config-beta.thinhoc.com') {
    return 'https://api-beta.thinhoc.com/v1/';
  } else {
    return 'https://api-dev.thinhoc.com/v1/';
  }
}

async function authenticateAPI(cls) {
  if(!window.vue.$auth.isAuthenticated) {
    window.console.warn('BUG: Call to API without proper authentication! See trace:');
    window.console.trace();
    return undefined;
  }
  const bearer = await getAPIToken();

  const API_BASE = await getAPIBase();

  const apiConf = new Configuration({
    basePath: API_BASE,
    baseOptions: {
      headers: {
        "Authorization": "Bearer " + bearer
      }
    }
  });
  return new cls(apiConf);
}

const loadingState = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR"
};

export { authenticateAPI, getAPIToken, loadingState, apiTokenKeyInStorage };