'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { authGuard } from "@/auth";
import { getAuth0Instance } from '@/auth/authWrapper';


Vue.use(VueRouter);

const ROUTER_LAST_ROUTE_KEY = 'router-last-route';


const activeOrgRedirect = (to, from, next) => {
  
  //Redirect if no active organization is set
  if(store.state.api.organization.activeOrganizationId || undefined) {
    return next();
  } else {
    return next('/manage/');
  }
};

const routes = [
  {
    path: '/',
    redirect: to => {
      if (store.state.api.isCloudVersion) {
        console.debug('(router) Redirecting to: /manage');
        return '/manage';
      }
      //Check if last route was saved
      const cachedRoute = sessionStorage.getItem(ROUTER_LAST_ROUTE_KEY);
      if (cachedRoute && typeof cachedRoute === 'string') {
        console.debug('(router) Redirecting to cached route: ' + cachedRoute);
        return cachedRoute;
      }
      console.debug('(router) Redirecting to: /session/local');
      return '/session/local';
    }
  },
  {
    path: '/access_token(.)*',
    redirect: to => {
      let token = window.location.hash.split('access_token=')[1].split('&')[0];
      getAuth0Instance().setupAuthentication(token);
      return '/';
    }
  },
  {
    path: '/app-catalog',
    name: 'App Catalog',
    component: () => import('../views/AppCatalog.vue')
  },
  {
    path: '/error(.)*',
    name: 'Login Error',
    component: () => import('../views/Login.vue'),
    props: route => {
      const description = route.params.pathMatch.split('error_description=')[1].split('&')[0];
      return { errorProp: true, errorPropDescription: description }
    }
  },
  {
    path: '/demo/login',
    name: 'Demo Login Page',
    component: () => import('../views/Login.vue'),
    props: route => ({ demoMode: true })
  },
  {
    path: '/factory-reset',
    name: 'Factory Reset',
    component: () => import('../views/FactoryReset.vue')
  },
  {
    path: '/mirror',
    name: 'Mirror',
    component: () => import('../views/Mirror.vue')
  },
  {
    path: '/greeter',
    name: 'Greeter',
    component: () => import('../views/Greeter.vue')
  },
  {
    path: '/screensaver',
    name: 'Screensaver',
    component: () => import('../views/Screensaver.vue')
  },
  {
    path: '/fbw',
    name: 'FirstBootWizard',
    component: () => import('../components/Greeter/FirstBootWizard.vue')
  },
  {
    path: '/session/',
    redirect: '/session/local'
  },
  {
    path: '/session/login',
    name: 'Login Page',
    component: () => import('../views/Login.vue'),
    props: route => ({ demoMode: route.query.demo, redirectUrl: route.query.redirectUrl })
  },
  {
    path: '/session/:profileId',
    name: 'Session',
    component: () => import('../views/Session.vue'),
    beforeEnter: (to, from, next) => {
      if(to.path !== "/session/local") {
        return authGuard(to, from, next);
      }
      return next();
    },
    props: route => ({ profileId: route.params.profileId})
  },
  {
    path: '/session/:profileId/settings',
    name: 'Settings',
    beforeEnter: authGuard,
    component: () => import('../views/Settings.vue'),
    props: route => ({ profileId: route.params.profileId})
  },
  {
    path: '/manage/login',
    name: 'Cloud Management Login Page',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/manage',
    name: 'Management',
    beforeEnter: authGuard,
    component: () => import('../views/Management.vue'),
    children: [
      {
        path: "/",
        beforeEnter: authGuard,
        component: () => {
          return import('../components/Management/OrganizationSelector.vue');
        }
      },
      {
        path: "org/select",
        beforeEnter: authGuard,
        component: () => import('../components/Management/OrganizationSelector.vue'),
      },
      {
        path: "org/:orgId",
        beforeEnter: authGuard,
        component: () => import('../components/Management/Organization.vue'),
      },
      {
        path: "profile/:profileID",
        beforeEnter: authGuard,
        component: () => import('../components/Management/Profile.vue'),
      },
      {
        path: "machines",
        beforeEnter: multiguard([activeOrgRedirect, authGuard]),
        component: () => import('../components/Management/Machines.vue'),
      },
      {
        path: "machines/deployment/:deployment",
        beforeEnter: multiguard([activeOrgRedirect, authGuard]),
        component: () => import('../components/Management/Machines.vue'),
      },
      {
        path: "users",
        beforeEnter: multiguard([activeOrgRedirect, authGuard]),
        component: () => import('../components/Management/Users.vue'),
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  },
];

const router = new VueRouter({
  routes
});

router.afterEach((to, from) => {
  if (to.fullPath.includes('login') || to.fullPath.includes('access_token') || to.fullPath.includes('error')) {
    return;
  }
  sessionStorage.setItem(ROUTER_LAST_ROUTE_KEY, to.fullPath);
});

export default router;
