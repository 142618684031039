import { OrganizationControllerApi} from '../../../lib/thinhoc-api'
import { authenticateAPI } from './utils';

const state = {
  userList: [],
  userListState: "EMPTY"
}

const getters = {
}

const mutations = {
  setUserList(state, userList) {
    state.userList = userList
  },
  setUserListState(state, userListState) {
    state.userListState = userListState
  },
}

const actions = {
  async loadUserList(ctx) {
    ctx.commit('setUserListState', 'LOADING')
    if (!ctx.rootState.api.organization.activeOrganizationId) {
      console.warn("Active Organization not set!")
    }
    const api = await authenticateAPI(OrganizationControllerApi)
    api.organizationControllerGetUsers(ctx.rootState.api.organization.activeOrganizationId)
      .then(async (res) => {
        console.log(res)

        ctx.commit('setUserList', res.data)
        ctx.commit('setUserListState', 'LOADED')
      })
      .catch((err) => {
        console.warn(err)
        ctx.commit('setUserListState', 'ERROR')
      })
  }
}




export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
