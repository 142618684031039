'use strict';

import { apiTokenKeyInStorage } from '@/store/modules/api/utils';
import dialogs from '@/components/dialogs';
import Vue from "vue";
import { WebAuth } from "auth0-js";

const AUTH_TOKEN_KEY = 'thoc-auth0-token';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getAuth0Instance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) {
    return instance;
  }

  instance = new Vue({
    data() {
      return {
        loading: false,
        isAuthenticated: false,
        user: {},
        webAuth0Client: new WebAuth({
          domain: options.domain,
          clientID: options.clientId,
          redirectUri: redirectUri,
          leeway: 5,
          responseType: 'token',
        }),
        accessToken: '',
        popupOpen: false,
        error: null,
        logoutTimeout: null,
      };
    },
    created() {
      // Try to login with cached token
      const token = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (token) {
        this.getUserByToken(token);
      }
    },
    methods: {
      checkSession(options, resultHandler, errorHandler) {
        return this.webAuth0Client.checkSession(
          options,
          (error, authResult) => {
            if (error) {
              if(error.code === 'consent_required') {
                // Redirect to Auth0 for granting consent
                this.webAuth0Client.authorize(options);
              }
              if (errorHandler) {
                errorHandler(error);
                return;
              }
              throw error;
            }
            if (authResult && resultHandler) {
              resultHandler(authResult);
            }
          });
      },
      getTokenWithPopup(options, resultHandler, errorHandler) {
        return this.webAuth0Client.popup.authorize(
            options,
            (error, authResult) => this._universalCallback(error, authResult, resultHandler, errorHandler)
        );
      },
      logout(options) {
        // Delete cached token
        sessionStorage.removeItem(AUTH_TOKEN_KEY);
        let vueRouterReturnTo = '/';
        if (!options) {
          options = {};
        }
        sessionStorage.removeItem(apiTokenKeyInStorage);
        if (options.returnTo) {
          vueRouterReturnTo = options.returnTo;
        }
        options.returnTo = window.location.origin; //Default behaviour
        window.vue.$router.push(vueRouterReturnTo);
        this.isAuthenticated = false;
        this.user = {};
        this.webAuth0Client.logout(options);
      },
      changePassword(email, resultHandler, errorHandler) {
        return this.webAuth0Client.changePassword(
            {
              email: email,
              connection: 'Username-Password-Authentication'
            },
            (error, result) => this._universalCallback(error, result, resultHandler, errorHandler)
        );
      },
      async signUp(email, password, resultHandler, errorHandler) {
        return this.webAuth0Client.signup(
          {
            email: email,
            password: password,
            connection: 'Username-Password-Authentication'
          },
          (error, result) => this._universalCallback(error, result, resultHandler, errorHandler)
        );
      },
      async loginWithAPI(email, password, errorHandler) {
        this.webAuth0Client.login(
            {
              email: email,
              password: password,
            },
            (error, result) => this._universalCallback(error, result, null, errorHandler) // auth0.login doesn't have a return value
        );
      },
      setupAuthentication(token) {
        this.getUserByToken(token);
        this.setLogoutTimeout();
        window.addEventListener('click', this.setLogoutTimeout);
      },
      getUserByToken(token) {
        this.loading = true;
        this.accessToken = token;
        let ctx = this;
        try {
          ctx.webAuth0Client.client.userInfo(
            token,
            function (err, user) {
              ctx.loading = false;
              if (err) {
                window.console.log(err);
                return;
              }
              // Save token to sessionStorage
              sessionStorage.setItem(AUTH_TOKEN_KEY, token);
              ctx.user = user;
              ctx.isAuthenticated = !!user;
            }
          );
        } catch (e) {
          this.loading = false;
          window.console.error(e);
        }
      },
      _universalCallback(error, result, resultHandler, errorHandler) {
        if (error) {
          if (errorHandler) {
            errorHandler(error);
            return;
          }
          throw error;
        }
        if (result && resultHandler) {
          resultHandler(result);
        }
      },
      setLogoutTimeout() {
        clearTimeout(this.logoutTimeout);
        this.logoutTimeout = setTimeout(
            async () => {
              await dialogs.inform('', vue.$i18n.t('login.admin-login-timed-out'));
              this.logout({returnTo: store.state.api.isCloudVersion ? 'session/login' : '/session/local'});
              this.disableLogoutTimeout();
            },
            60 * 60 * 1000
        );
      },
      disableLogoutTimeout() {
        clearTimeout(this.logoutTimeout);
        window.removeEventListener('click', this.setLogoutTimeout);
      }
    },
    watch: {
      isAuthenticated: function(newVal, oldVal) {
        if(newVal && !oldVal) {
          window.dispatchEvent(new Event('auth-login'));
        } else if(!newVal && oldVal) {
          window.dispatchEvent(new Event('auth-logout'));
        }
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
