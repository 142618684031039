
const state = {
  machineContext: {
  },
  status: 'EMPTY',
  errorDescription: null
}

const getters = {
}

const mutations = {
  setContext(state, context) {
    state.machineContext = context
    state.errorDescription = null
  }
}

const actions = {
  async load(ctx) {
    //Timeout
    setTimeout(() => {
      if(ctx.state.status === 'LOADING') {
        ctx.state.errorDescription = "Timeout while loading machine context!"
        ctx.state.status = 'ERROR'
      }
    }, 3000);

    state.status = 'LOADING'
    if(typeof pywebview !== 'undefined') {
      await pywebview.api.load_machine_context()
        .then((res) => {
          state.status = 'LOADED'
          ctx.commit('setContext', res)
        })
        .catch((err) => {
          console.warn("Failed to load machine context!")
          state.status = 'ERROR'
          ctx.state.errorDescription = err.message
        })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}