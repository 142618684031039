import lightDM from './light-dm'
import machineConfig from './machine-config'
import userConfig from './user-config'
import sip from './sip'
import machineContext from './machine-context'
import whiteboard from "./whiteboard";

const state = {
  machineStatus: [],
}

const getters = {
  machineStatus(state) {
    return state.machineStatus || [];
  }
}

const mutations = {

}

const actions = {
  async factoryReset(ctx) {
    if(typeof pywebview !== 'undefined') {
      pywebview.api.factory_reset()
    }
  }
}

const modules = {
  lightDM,
  machineConfig,
  machineContext,
  userConfig,
  sip,
  whiteboard,
}

//Load config from pywebview api
window.addEventListener('pywebviewready', async function () {
  setInterval(async () => {
    if (typeof pywebview === "undefined") return;
      pywebview.api.get_machine_status().then((status) => {
      state.machineStatus = status;
    });
  }, 1000);
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}