import ptr from 'json-pointer'
import root_schema from '@/assets/config/rootschema.json'
import { authenticateAPI, loadingState } from './utils';
import { ajv } from './util-ajv';
import { OrganizationProfileControllerApi, ProfileControllerApi } from '../../../lib/thinhoc-api';
import dialogs from '../../../components/dialogs'


const ProfileAPI = {
  /**
   *
   * @param name {String}
   * @param organizationId {String}
   * @param deployment {String}
   * @returns {Number | null}
   */
  create: async function (name, organizationId, deployment) {
    try {
      const api = await this._getApiInstance();
      const result = await api.profileControllerCreate({ name: name, organizationId: organizationId, deployment: deployment });
      return result.data.id;
    } catch (err) {
      dialogs.showAPIError('Failed to create profile! Try again later.', err);
    }
  },
  /**
   *
   * @param profileId {Number}
   * @returns {Object | null}
   */
  read: async function (profileId) {
    try {
       const api = await this._getApiInstance();
       const res = await api.profileControllerFindById(profileId);
       return res.data;
    } catch (err) {
      console.warn(err);
    }
  },
  /**
   *
   * @param profileId {Number}
   * @param profileData {Object}
   * @returns {Boolean}
   */
  update: async function (profileId, profileData) {
    try {
      const api = await this._getApiInstance();
      await api.profileControllerUpdateById(profileId, profileData);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  },
  /**
   *
   * @param profileId {Number}
   * @returns {Boolean}
   */
  delete: async function (profileId) {
    try {
      const api = await this._getApiInstance();
      await api.profileControllerDeleteById(profileId);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  },
  /**
   * @returns {ProfileControllerApi}
   */
  _getApiInstance: async function () {
    return await authenticateAPI(ProfileControllerApi);
  }
}

const OrganizationProfileAPI = {
  /**
   *
   * @param organizationId {Number}
   * @param filter {String}
   * @returns {Object | null}
   */
  read: async function (organizationId, filter) {
    try {
       const api = await this._getApiInstance();
       const res = await api.organizationProfileControllerFind(organizationId, filter);
       return res.data;
    } catch (err) {
      console.warn(err);
    }
  },
  /**
   * @returns {OrganizationProfileControllerApi}
   */
   _getApiInstance: async function () {
    return await authenticateAPI(OrganizationProfileControllerApi);
  },
}

const state = {
  profilesList: [
  ],
  profilesListState: "EMPTY",
  activeProfileId: -1,
  activeProfile: {},
  activeProfileState: "EMPTY"
}

const getters = {
  activeProfiles(state) {
    return state.profilesList
  },
  getProfileById: (state) => (id) => {
    return state.profiles.find(profile => (profile.id === parseInt(id)))
  }

}

const mutations = {
  setProfilesList(state, list) {
    state.profilesList = list
  },
  setProfilesListState(state, profileState) {
    state.profilesListState = profileState
  },
  setActiveProfile(state, profile) {
    state.activeProfile = profile
    state.activeProfileId = profile.id
  },
  setActiveProfileState(state, profileState) {
    state.activeProfileState = profileState
  },
  validateActiveDefinition(state) {
    console.debug('(store[api/profiles]) validateActiveDefinition')
    const config_copy = JSON.parse(JSON.stringify(state.activeProfile.definition))
    ajv.validate(root_schema, config_copy)
    state.activeProfile.definition = config_copy
  },
  patchActiveDefinition(state, payload) {
    console.debug('(store[api/profiles]) patchActiveDefinition: path="' + payload['path'] + '" data="' + JSON.stringify(payload['data']) + '"')
    ptr.set(state.activeProfile.definition, payload['path'], payload['data'])
  },
}

const actions = {
  async createProfile(ctx, {deployment, name}) {
    return new Promise(async (resolve, reject) => {
      const profileName = name || 'Unnamed Profile 1';
      const newProfileId = await ProfileAPI.create(profileName, ctx.rootState.api.organization.activeOrganizationId, deployment);
      if (!newProfileId) {
        reject();
        return;
      }
      ctx.dispatch('loadProfile', newProfileId);
      ctx.dispatch('loadProfilesList');
      resolve(newProfileId);
    });
  },
  async loadProfile(ctx, profileId) {
    ctx.commit('setActiveProfileState', 'LOADING');
    const profileData = await ProfileAPI.read(profileId);
    if (!profileData) {
      ctx.commit('setActiveProfileState', 'ERROR');
      return;
    }
    console.log(profileData)
    ctx.commit('setActiveProfile', profileData);
    ctx.commit("validateActiveDefinition");
    ctx.commit('setActiveProfileState', 'LOADED');
  },
  async saveProfile(ctx) {
    const result = await ProfileAPI.update(ctx.state.activeProfileId, ctx.state.activeProfile);
    if (!result) {
      return;
    }
    ctx.dispatch("loadProfilesList");
  },
  async deleteProfile(ctx) {
    const result = await ProfileAPI.delete(ctx.state.activeProfileId);
    if (!result) {
      return;
    }
    ctx.dispatch("loadProfilesList");
  },
  async copyProfile(ctx, {currentProfileId, newProfileName, deployment}) {
    ctx.commit('setActiveProfileState', 'LOADING');
    const oldProfileData = await ProfileAPI.read(currentProfileId);
    oldProfileData.name = newProfileName;
    const newProfileId = await ProfileAPI.create(newProfileName, ctx.rootState.api.organization.activeOrganizationId, deployment);
    if (!newProfileId) {
      ctx.commit('setActiveProfileState', 'ERROR');
      return;
    }
    await ProfileAPI.update(newProfileId, oldProfileData);
    ctx.dispatch("loadProfilesList");
    ctx.commit('setActiveProfileState', 'LOADED');
    return newProfileId;
  },
  async loadProfilesList(ctx) {    
    const filter = JSON.stringify({fields: {'id': true, 'name': true, 'deployment': true}})
    if (!ctx.rootState.api.organization.activeOrganizationId) {
      console.warn("Active Organization not set!")
    }
    ctx.commit('setProfilesListState', loadingState.LOADING);
    const result = await OrganizationProfileAPI.read(ctx.rootState.api.organization.activeOrganizationId, filter);
    if (!result) {
      ctx.commit('setProfilesListState', loadingState.ERROR);
      return;
    }
    ctx.commit('setProfilesListState', loadingState.LOADED);
    console.log(result);
    ctx.commit('setProfilesList', result);
  },
  patchActiveDefinition(context, payload) {
    context.commit("patchActiveDefinition", payload)
    context.commit("validateActiveDefinition")
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}